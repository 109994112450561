import Vue from 'vue';
import Router from 'vue-router';


import webpass from './routes/webpass';
import workflow from './routes/workflow';
import auth from './routes/auth';
import logger from './routes/logger';
import other from './routes/other';
import stock from './routes/stock';


// 注册单页
const index = () => import('@/pages/index');
const test = () => import('@/pages/test');
const anchor = () => import('@/pages/anchor');
const login = () => import('@/pages/login');
const body  = () => import('@/layout/default/body');
// const error = () => import('@/pages/error/404');


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);

// let basePath = process.env.NODE_ENV=='development' ? '' : '/dist/admin/';
export default new Router({
    // base: basePath,
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/login',
            name: 'root',
        },
        {
            path: '/login',
            name: 'login',
            component: login,
        },
        {
            path: '/test',
            name: 'test',
            component: test,
        },
        {
            path: '/anchor',
            name: 'anchor',
            component: anchor,
        },
        {
            path: '/home',
            component: body,
            children: [
                {
                    path: '/index',
                    name: 'index',
                    component: index,
                },
                webpass,
                workflow,
                logger,
                auth,
                other,
                stock,
            ]
        },
    ]
})
