const container = () => import('@/layout/default/container');

// 仓储映射管理路由
export default {
    path: '/stock',
    component: container,
    children: [
        {
            path: '/stock-map',
            name: 'StockMap',
            component: () => import('@/pages/webpass/stockmap/ListView'),
            meta: { title: '仓储映射管理' }
          },
          {
            path: '/stock-map/create',
            name: 'StockMapCreate',
            component: () => import('@/pages/webpass/stockmap/CreateView'),
            meta: { title: '新建仓储映射' }
          },
          {
            path: '/stock-map/edit/:id',
            name: 'StockMapEdit',
            component: () => import('@/pages/webpass/stockmap/EditView'),
            meta: { title: '编辑仓储映射' },
            props: true
          },
          // 业务链仓储路由
          {
            path: '/biz-stock-map',
            name: 'BizStockMap',
            component: () => import('@/pages/webpass/bizstockmap/ListView'),
            meta: { title: '业务链仓储管理' }
          },
          {
            path: '/biz-stock-map/create',
            name: 'BizStockMapCreate',
            component: () => import('@/pages/webpass/bizstockmap/CreateView'),
            meta: { title: '新建业务链仓储' }
          },
    ]
}
